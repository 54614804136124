.spin {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: red;
  }
  
  .spinning {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: 3;
    animation-fill-mode: forwards;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1080deg);
    }
  }
  
  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5; /* replace with your desired background color */
  }
  